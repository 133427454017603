import '../../scss/layout/siteheader.scss';

class Header {
    constructor (element, options) {
        const settings = {
            initAttr: 'data-header',
            visibleClassname: 'is--visible',
            hiddenClassname: 'is--hidden',
            eventScroller: null
        };

        this.settings = Object.assign({}, settings, options);
        this.$toBasketInput = document.querySelector('#sub-nav_toggle-basket');

        this.$header = element;
    }

    initialize () {
        window.headerMovement = true;
        this.setEvents();

        if (this.$toBasketInput) {
            this.goToBasketCheck();
        }
    }

    setEvents () {
        const eventScroller = this.settings.eventScroller;

        if (eventScroller !== null) {
            eventScroller.customFunctions.push(() => {
                const scrollInfo = eventScroller.getScrollInfo();
                const position = scrollInfo.position;
                const direction = scrollInfo.direction;
                const $productSupportFilterBar = document.querySelector('.product-support-form__results-filter-bar-container');
                const headerHeight = this.$header.offsetHeight;

                if (window.headerMovement === true) {
                    if (position > 45) {
                        if (direction === 'down' && this.$header.getAttribute('noscroll') === null) {
                            const $select = this.$header.querySelector('.selectr-container.open');
                            if ($select) {
                                this.$header.classList.remove(this.settings.hiddenClassname);
                                this.$header.classList.add(this.settings.visibleClassname);
                            } else {
                                this.$header.classList.add(this.settings.hiddenClassname);
                                this.$header.classList.remove(this.settings.visibleClassname);
                            }

                            if ($productSupportFilterBar) {
                                $productSupportFilterBar.style.top = 0;
                            }
                        } else if (direction === 'up') {
                            this.$header.classList.remove(this.settings.hiddenClassname);
                            this.$header.classList.add(this.settings.visibleClassname);

                            if ($productSupportFilterBar) {
                                $productSupportFilterBar.style.top = headerHeight + 'px';
                            }
                        }
                    } else {
                        this.$header.classList.remove(this.settings.hiddenClassname);
                        this.$header.classList.remove(this.settings.visibleClassname);
                    }
                }
            });
        }
    }

    goToBasketCheck () {
        this.basketUrl = this.$toBasketInput.getAttribute('data-basket-url');

        this.$toBasketInput.addEventListener('change', () => {
            const empty = this.$toBasketInput.getAttribute('data-basket-empty');
            const notEmpty = this.$toBasketInput.getAttribute('data-basket-not-empty');
            const localBasket = localStorage.getItem('basket');
            const $basketFlyOut = document.querySelector('.siteheader__navigation-flyout-container');
            let localBasketObject = null;

            if (localBasket) {
                localBasketObject = JSON.parse(localStorage.getItem('basket'));
            }

            if (empty !== null) {
                if (localBasket) {
                    if (localBasketObject.length > 0) {
                        if ($basketFlyOut) {
                            $basketFlyOut.remove();
                        }
                        window.location.href = this.basketUrl;
                    }
                }
            } else {
                if (notEmpty !== null) {
                    if ($basketFlyOut) {
                        $basketFlyOut.remove();
                    }
                    window.location.href = this.basketUrl;
                } else {
                    if (localBasket) {
                        if (localBasketObject.length > 0) {
                            if ($basketFlyOut) {
                                $basketFlyOut.remove();
                            }
                            window.location.href = this.basketUrl;
                        }
                    }
                }
            }
        });
    }
}

export default Header;

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $header = $context.querySelector('[data-header="root"]');

        if ($header) {
            const header = new Header($header, {
                eventScroller: window.eventScroller
            });
            header.initialize();
        }
    }
});
